import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { TypeRequest } from 'src/app/core/model/type-request.model';

@Injectable({
  providedIn: 'root'
})
export class TypeRequestService {

  url: string;
  sizePage: number;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiUrl}/api/v1/tipo_solicitacoes`;
    this.sizePage = environment.maxItemsPage;
  }

  async search(filter: string = null, page: number = 0, size: number = this.sizePage): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: page.toString(),
        size: size.toString()
      }
    });

    if (filter) {
      params = params.append('busca', filter);
    }
    params = params.append('sort', 'descricao,asc');

    const response = await this.http.get<any>(this.url, { params })
      .toPromise();

    const typeRequests = response['content'];
    const result = {
      typeRequests,
      size: response['size'],
      totalElements: response['totalElements'],
      first: response['first'],
      last: response['last']
    };

    return result;
  }

  async searchList(): Promise<TypeRequest[]> {
    const response = await this.http.get<TypeRequest[]>(`${this.url}/lista`)
      .toPromise();

    return response;
  }

  async searchId(id: number): Promise<TypeRequest> {
    const response = await this.http.get<TypeRequest>(`${this.url}/${id}`)
      .toPromise();

    return response;
  }

  async add(typeRequest: TypeRequest): Promise<TypeRequest> {
    const response = await this.http.post<TypeRequest>(this.url, typeRequest)
      .toPromise();

    return response;
  }

  async update(id: number, typeRequest: TypeRequest): Promise<TypeRequest> {
    const response = await this.http.put<TypeRequest>(`${this.url}/${id}`, typeRequest)
      .toPromise();

    return response;
  }

  async remove(id: number): Promise<void> {
    await this.http.delete(`${this.url}/${id}`)
      .toPromise();

    return null;
  }
}
