import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { Request } from 'src/app/core/model/request.model';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  url: string;
  sizePage: number;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiUrl}/api/v1/solicitacoes`;
    this.sizePage = environment.maxItemsPage;
  }

  async search(filter: string = null, page: number = 0, size: number = this.sizePage): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: page.toString(),
        size: size.toString()
      }
    });

    if (filter) {
      params = params.append('busca', filter);
    }
    params = params.append('sort', 'id,desc');

    const response = await this.http.get<any>(this.url, { params })
      .toPromise();

    const requests = response['content'];
    const result = {
      requests,
      size: response['size'],
      totalElements: response['totalElements'],
      first: response['first'],
      last: response['last']
    };

    return result;
  }

  async searchId(id: number): Promise<Request> {
    const response = await this.http.get<Request>(`${this.url}/${id}`)
      .toPromise();

    return response;
  }

  async countRequestStatus(idUsuario: number, status: string): Promise<number> {
    let params = new HttpParams({
      fromObject: {
        page: '0'
      }
    });

    if (idUsuario == null) {
      params = params.append('busca', `status~*${status}*`);
    } else {
      params = params.append('busca', `usuario_id:${idUsuario},status:${status}`);
    }

    const response = await this.http.get<any>(this.url, { params })
      .toPromise();

    return response['totalElements'];
  }

  async add(request: Request): Promise<Request> {
    const response = await this.http.post<Request>(this.url, request)
      .toPromise();

    return response;
  }

  async update(id: number, request: Request): Promise<Request> {
    const response = await this.http.put<Request>(`${this.url}/${id}`, request)
      .toPromise();

    return response;
  }

  async remove(id: number): Promise<void> {
    await this.http.delete(`${this.url}/${id}`)
      .toPromise();

    return null;
  }
}
